import React from "react";

const GoogleDriveFolderIcon = () => {
  return (
    <div>
      <svg
        focusable="false"
        viewBox="0 0 24 24"
        height="24"
        width="24"
        fill="currentColor"
        className="a-s-fa-Ha-pa"
      >
        <g fill="#444746">
          <path
            d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"
            fill="#777575"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </g>
      </svg>
    </div>
  );
};

export default GoogleDriveFolderIcon;
