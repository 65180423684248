import React from "react";

const ImageIcon = () => {
  return (
    <div>
      <svg
        className="k2eJge"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 14.222V1.778C16 .796 15.204 0 14.222 0H1.778C.796 0 0 .796 0 1.778v12.444C0 15.204.796 16 1.778 16h12.444c.982 0 1.778-.796 1.778-1.778zM4.889 9.333l2.222 2.671L10.222 8l4 5.333H1.778l3.11-4z"
          fill="#EA4335"
        ></path>
      </svg>
    </div>
  );
};

export default ImageIcon;
