import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
// import MicrosoftIcon from "../asstes/microsofticon.png";
import MicrosoftIcon from "../icons/microsoftIcon"
import { useLocation } from "react-router-dom";
const Login = ({ fetchURL, getlocationarray }) => {
  const [URL, setURL] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Get the value of 'code' and 'state' from the URL
  const msCode = searchParams.get("code");
  const state = searchParams.get("state");

  const login = async () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
       width=500,height=600,left=500,top=100`;
    let newWindow = window.open(
      `${URL}&state=${getlocationarray?.portalId}`,
      "test",
      params
    );
    const checkWindowClosed = setInterval(() => {
      if (newWindow.closed) {
        setTimeout(() => {
          clearInterval(checkWindowClosed);
        }, 2000);
        console.log("Windoe is cloesd")
        window.location.reload();
      }
    }, 5000);
  };

  useEffect(() => {
    const getMicrosoftURL = async () => {
      const response = await fetch(`${fetchURL}/microsoft/microsoft_url`);
      const data = await response.json();
      if (data?.code === 200) {
        setURL(data?.URL);
      }
    };
    getMicrosoftURL();
  }, []);

  useEffect(() => {
    const saveAuthToken = async () => {
      const requestData = {
        code: msCode,
        portalID: state,
      };
      await fetch(`${fetchURL}/microsoft/getAccessToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      window.close();
    };

    if (msCode) {
      saveAuthToken();
    }
  }, [msCode]);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <MicrosoftIcon />
        <Typography sx={{ my: "1em" }} variant="h5">
          Connect your microsoft account
        </Typography>
        <Button
          variant="contained"
          color="warning"
          sx={{ boxShadow: "none", textTransform: "none" }}
          onClick={() => login()}
        >
          Connect To Microsoft
        </Button>
      </Box>

    </>
  );
};

export default Login;
