import axios from "axios";
// const fetchURL = "http://localhost:8000/";
const fetchURL = "https://drive.solarinbound.app/";
export const Api = function (querystring = {}, Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: "post",
      url: fetchURL + Url,
      headers: {
        "Content-Type": "application/json",
        authorization: window.localStorage.getItem("Microsoft-Access_token"),
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
        } else {
          resolve(response?.data);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const GetApi = function (querystring = {}, Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: "post",
      url: fetchURL + Url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        resolve(response?.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const FormDataAPi = function (querystring = {}, Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: "post",
      url: fetchURL + Url,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: window.localStorage.getItem("Microsoft-Access_token"),
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        if (response?.data?.code === 403) {
        } else {
          resolve(response?.data);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
