// @ts-nocheck
import React, { useState, useEffect } from "react";
import Login from "./login";
import Files from "./files";
import { Container } from "@mui/material";
import { calculateTimeDifference } from "../utility/microsoftUtility";
import { Box, CircularProgress } from "@mui/material";
const Microsoft = ({ fetchURL }) => {
  const [token, setToken] = useState();
  const [getlocationarray, setgetlocationarray] = useState({});
  const [loading, setLoading] = useState(false);
  const getObjectQuery = () => {
    const query = new URLSearchParams(window.location.search);
    setgetlocationarray({
      id: query.get("id"),
      type: query.get("type"),
      userId: query.get("userId"),
      portalId: query.get("portalId"),
      folderId: query.get("folderId") ? query.get("folderId") : "",
    });
    if (!token) {
      getAuthToken();
    }
  };

  const checkExpireToken = async () => {
    const expireTime = window.localStorage.getItem("Microsoft-expires_in");
    if (expireTime) {
      const CurrentDate = new Date();
      const CurrentISOTime = CurrentDate.toISOString();
      let timeDiffernce = calculateTimeDifference(CurrentISOTime, expireTime);
      if (timeDiffernce < 10) {
        refreshToken()
      }
    }
  };

  const getAuthToken = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    const requestData = {
      portalID: query.get("portalId"),
    };
    const response = await fetch(`${fetchURL}/microsoft/getAuthTokenByID`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();
    if (data?.code === 200) {
      window.localStorage.setItem(
        "Microsoft-Access_token",
        data?.exsitsID?.responseData?.access_token
      );
      window.localStorage.setItem(
        "Microsoft-refresh_token",
        data?.exsitsID?.responseData?.refresh_token
      );
      const currentTime = new Date();
      const expireTime = new Date(currentTime.getTime() + (60 * 60 * 1000))
      window.localStorage.setItem(
        "Microsoft-expires_in",
        expireTime
      );
      setToken(data?.exsitsID?.responseData?.access_token);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const query = new URLSearchParams(window.location.search);
      const requestData = {
        refresh_token: window.localStorage.getItem("Microsoft-refresh_token"),
        portalID: query.get("portalId"),
      };
      const response = await fetch(
        `${fetchURL}/microsoft/refreshMSAccessToken`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      if (data?.auth_token && data.code === 200) {
        window.localStorage.setItem("Microsoft-Access_token", data?.auth_token?.access_token);
        const currentTime = new Date();
        const expireTime = new Date(currentTime.getTime() + (60 * 60 * 1000))
        window.localStorage.setItem(
          "Microsoft-expires_in",
          expireTime
        );
        setToken(data?.auth_token?.access_token);
      }
    } catch (err) {
      console.log(err?.message)
    }
  }

  useEffect(() => {
    getObjectQuery();
    checkExpireToken();
  }, []);

  return (
    <>
      {loading ? <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress />
      </Box> : <Container sx={{ height: "100vh", width: "100vw" }}>
        {!token && (
          <Login
            fetchURL={fetchURL}
            getlocationarray={getlocationarray}
          />
        )}
        {token && (
          <Files
            token={token}
            setToken={setToken}
            getlocationarray={getlocationarray}
            refreshToken={refreshToken}
          />
        )}
      </Container>}
    </>

  );
};

export default Microsoft;
