import React from "react";

const VideoIcon = () => {
  return (
    <div>
      <svg
        className="k2eJge"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path
          d="M12.8 0l1.6 3.2H12L10.4 0H8.8l1.6 3.2H8L6.4 0H4.8l1.6 3.2H4L2.4 0h-.8C.72 0 .008.72.008 1.6L0 11.2c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V0h-3.2z"
          fill="#EA4335"
        ></path>
      </svg>
    </div>
  );
};

export default VideoIcon;
