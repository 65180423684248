function calculateTimeDifference(isoTime1, isoTime2, unit = "minutes") {
  const date1 = new Date(isoTime1);
  const date2 = new Date(isoTime2);

  let differenceInMilliseconds = date2 - date1;

  let difference;

  switch (unit) {
    case "seconds":
      difference = differenceInMilliseconds / 1000;
      break;
    case "minutes":
      difference = differenceInMilliseconds / (1000 * 60);
      break;
    case "hours":
      difference = differenceInMilliseconds / (1000 * 60 * 60);
      break;
    case "days":
      difference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      break;
    default:
      difference = differenceInMilliseconds; // default to milliseconds
  }

  return difference;
}

function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName.trim() === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}
export { calculateTimeDifference, getCookie };
