// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Api, FormDataAPi } from "./common";
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Typography,
  Checkbox,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/AddOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import NoteIcon from "@mui/icons-material/Note";
import GoogleFolderIcon from "../icons/GoogleDriveFolderIcon";
import GoogleImageIcon from "../icons/GoogleImageIcon";
import GoogleVideoIcon from "../icons/GoogleVideoIcon";
import GoogleDocIcon from "../icons/GoogleDocIcon";
// search icons
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  minWidth: 300,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100% !important",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100% !important",
  },
}));

const Files = ({ token, setToken, getlocationarray, refreshToken }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [renamingLoading, setRenamingLoading] = useState(false);
  const [filesarray, setFilesarray] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]); // use for save
  const [breadCrumbLinks, setBreadCrumbLinks] = useState([]);
  const [anchorEls, setAnchorEls] = useState({});
  const [renamingFildId, setRenamingFileId] = useState("");
  const [renameText, setRenameText] = useState("");
  const [searchinput, setSearchinput] = useState("");
  const [open, setOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [currentactionobject, setCurrentactionobject] = useState({
    FolderId: "",
    DriverId: "",
  });
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [buttondisabled, setButtondisabled] = useState(false);
  const query = new URLSearchParams(window.location.search);

  const getdrives = async (id = "", name = "") => {
    setButtondisabled(true);
    if (searchinput) {
      setIsLoadingSearch(true);
    }
    const postdata = await Api(
      {
        searchQuery: searchinput,
        portalId: query.get("portalId") ? query.get("portalId") : "",
        childfolderID: query.get("folderId") ? query.get("folderId") : id,
      },
      "microsoft/getOneDriveFiles"
    );
    if (postdata?.code === 200) {
      let array = [];
      if (postdata?.OneDriveFilesArray?.length > 0) {
        postdata?.OneDriveFilesArray?.map((value) => {
          array.push(value?.file);
        });
      }
      if (name || id) {
        if (!breadCrumbLinks.filter((x) => x.id === id)?.length) {
          setBreadCrumbLinks((prevState) => [
            ...prevState,
            { name: name, id: id },
          ]);
        } else {
          const newArray = breadCrumbLinks.filter((item) => item.id <= id);
          setBreadCrumbLinks(newArray);
        }
      } else {
        setBreadCrumbLinks([]);
      }
      setIsLoadingSearch(false);
      setIsLoading(false);
      setFilesarray(array);
      GetFileFromDB(array);
      setButtondisabled(false);
    } else if (postdata?.code === 401) {
      setToken(null);
      setIsLoadingSearch(false);
    } else {
      setIsLoadingSearch(false);
      setToken(null);
    }
  };

  const handleSearchChange = (e) => {
    setSearchinput(e.target.value);
    let searchQuery = e.target.value.toLowerCase();
    if (searchQuery) {
      const searchWords = searchQuery.split(" ");
      const filteredData = filesarray.filter((obj) =>
        searchWords.some((word) => obj.name.toLowerCase().includes(word))
      );
      setFilesarray(filteredData);
    } else {
      getdrives();
    }
  };

  // call fun onchange checkbox
  const handleCheckboxChange = (file) => {
    const isSelected = selectedFiles.includes(file);
    if (isSelected) {
      setSelectedFiles(
        selectedFiles.filter((selectedFile) => selectedFile.id != file.id)
      );
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  // check the file is checked
  const handleChecked = (id) => {
    return selectedFiles.map((x) => x.id).includes(id);
  };

  // menu action
  const handleActionClick = (id, event) => {
    setAnchorEls({ ...anchorEls, [id]: event.target });
  };

  const handleActionClose = (id, event) => {
    setAnchorEls({ ...anchorEls, [id]: null });
    setRenamingFileId(null);
  };

  // renaming function
  const handleSetRenameItem = (id, event) => {
    handleActionClose(id, event);
    setRenamingFileId(id);
  };

  const renameFileAction = async (id, e) => {
    setRenamingLoading(true);
    const postdata = await Api(
      {
        folderID: id,
        portalId: query.get("portalId") ? query.get("portalId") : "",
        folderName: renameText,
      },
      "microsoft/renameOneDriveFile"
    );
    if (postdata?.code === 200) {
      setRenamingLoading(false);
      handleActionClose(id, e);
      setRenameText("");
      getdrives();
    } else {
      toast.error("Something went wrong!");
      setRenamingLoading(false);
      handleActionClose(id, e);
    }
  };

  // create folder button
  const handleOnCreateFolder = async () => {
    setIsLoading(true);
    const postdata = await Api(
      {
        childfolderID: currentactionobject?.FolderId,
        driveID: currentactionobject?.DriverId,
        folderName: folderName,
        portalId: query.get("portalId") ? query.get("portalId") : "",
      },
      "microsoft/createFileOneDrive"
    );
    if (postdata?.code === 200) {
      setFolderName("");
      setOpen(false);
      setTimeout(() => {
        getdrives(currentactionobject?.FolderId);
        setIsLoading(false);
      }, 2000);
    } else {
      toast.error("Something went wrong!");
      setIsLoading(false);
    }
  };

  //  upload file
  const handleUploadFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const form = new FormData();
      form.append("files", file);
      form.append("childfolderID", currentactionobject?.FolderId);
      form.append("portalId", query.get("portalId"));
      const postdata = await FormDataAPi(form, "microsoft/uploadFiles");
      if (postdata?.code === 200) {
        setOpenFileDialog(false);
        setTimeout(() => {
          getdrives(currentactionobject?.FolderId);
          setIsLoading(false);
        }, 5000);
      } else {
        toast.error("Something went wrong!");
        setOpenFileDialog(false);
        setIsLoading(false);
      }
    }
  };

  // save file to db
  const SaveFileToDB = async () => {
    setIsLoading(true);
    if (selectedFiles?.length) {
      selectedFiles.map((value, index) => {
        selectedFiles[index]["fileType"] = value?.file ? "File" : "Folder";
      });
    }
    let data = {
      data: {
        id: getlocationarray?.id,
        type: getlocationarray?.type,
        userId: getlocationarray?.userId,
        portalId: getlocationarray?.portalId,
        docs: selectedFiles,
      },
    };
    const postdata = await Api(data, "microsoft/saveOneDriveFiles");
    if (postdata?.code === 200) {
      setIsLoading(false);
      setSelectedFiles([]);
      getdrives(currentactionobject?.FolderId, "");
      window.parent.postMessage(JSON.stringify({ "action": "DONE" }), "*");
    } else {
      toast.error("Something went wrong!");
      setIsLoading(false);
    }
  };

  // get array from db
  const GetFileFromDB = async (array) => {
    if (array?.length) {
      let data = {
        id: query.get("id"),
        type: query.get("type"),
        userId: query.get("userId"),
        portalId: query.get("portalId"),
      };
      let arrayNew = [...array];
      const postdata = await Api(data, "microsoft/getSelectedOneDriveFile");
      if (postdata?.code === 200) {
        if (postdata?.selectedFileArray?.length) {
          arrayNew?.map((value, index) => {
            let check = postdata?.selectedFileArray.filter(
              (x) => x === value?.id
            );
            if (check?.length) {
              arrayNew[index]["selecteddata"] = true;
            } else {
              arrayNew[index]["selecteddata"] = false;
            }
          });
          if (array?.length) {
            setFilesarray(arrayNew);
          }
        }
      }
    }
  };

  const getType = (type) => {
    switch (type) {
      case "video/mp4":
        return "Video";
      case "video/x-msvideo":
        return "Video";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "Document";
      case "image/jpeg":
        return "Image";
      case "image/webp":
        return "WEBP";
      default:
        return type;
    }
  };

  useEffect(() => {
    if (token) {
      setCurrentactionobject({ FolderId: "", DriverId: "" });
      getdrives();
    }
  }, [token]);

  console.log(filesarray, "filesarray")
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <React.Fragment>
            <AppBar color="inherit" position="static" elevation={0}>
              <Toolbar variant="dense">
                <Box>
                  <Breadcrumbs aria-label="breadcrumb">
                    {breadCrumbLinks.length > 0 ? (
                      <Link
                        underline="hover"
                        component="button"
                        color="inherit"
                        onClick={() => {
                          getdrives();
                          setCurrentactionobject({
                            FolderId: "",
                            DriverId: "",
                          });
                        }}
                      >
                        My Drive
                      </Link>
                    ) : (
                      <Typography color="text.primary">My Drive</Typography>
                    )}
                    {breadCrumbLinks.map((link, index) =>
                      breadCrumbLinks?.length === index + 1 ? (
                        <Typography color="text.primary">
                          {" "}
                          {link?.name}
                        </Typography>
                      ) : (
                        <Link
                          key={link?.id}
                          underline="hover"
                          component="button"
                          color="inherit"
                          onClick={() => getdrives(link?.id)}
                        >
                          {link?.name}
                        </Link>
                      )
                    )}
                  </Breadcrumbs>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
              </Toolbar>
              <Toolbar variant="dense">
                <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={searchinput}
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </Search>
                  {isLoadingSearch ? (
                    <CircularProgress sx={{ marginLeft: 1 }} size={25} />
                  ) : null}
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                  <Button
                    sx={{ marginRight: "1rem", minWidth: 100 }}
                    onClick={SaveFileToDB}
                    disabled={!selectedFiles || selectedFiles.length === 0}
                    variant="contained"
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={() => setOpen(true)}>
                    Create a folder
                  </Button>
                  <IconButton
                    color="primary"
                    onClick={() => setOpenFileDialog(true)}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </AppBar>
            {filesarray.length > 0 ? (
              <TableContainer sx={{ marginTop: "2em" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell sx={{ maxWidth: 320 }}>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Created On</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesarray.map((file) => (
                      <TableRow
                        key={file.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Checkbox
                            disabled={file?.selecteddata}
                            checked={
                              file?.selecteddata
                                ? file?.selecteddata
                                : handleChecked(file?.id)
                            }
                            onChange={() => handleCheckboxChange(file)}
                          />
                        </TableCell>
                        <TableCell>
                          {file.file ? (
                            file.file.mimeType === "application/pdf" ? (
                              <PictureAsPdfIcon />
                            ) : file.file.mimeType === "image/png" ||
                              file.file.mimeType === "image/jpg" ||
                              file.file.mimeType === "image/jpeg" ? (
                              <GoogleImageIcon />
                            ) : file.file.mimeType === "application/zip" ? (
                              <FolderZipIcon color="warning" />
                            ) : file.file.mimeType === "video/x-msvideo" ||
                              file.file.mimeType === "video/mp4" ? (
                              <GoogleVideoIcon />
                            ) : file.file.mimeType ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                              <GoogleDocIcon />
                            ) : (
                              <FileOpenIcon />
                            )
                          ) : (
                            <GoogleFolderIcon
                              onClick={() => {
                                getdrives(file?.id, file?.name);
                                setCurrentactionobject({
                                  FolderId: file?.id,
                                  DriverId: file?.parentReference?.driveId,
                                });
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell sx={{ maxWidth: 320 }}>
                          {renamingFildId === file.id ? (
                            <>
                              <TextField
                                type="text"
                                label="New name"
                                variant="outlined"
                                size={"small"}
                                defaultValue={file.name}
                                onChange={(e) => setRenameText(e.target.value)}
                              />
                              {renamingLoading ? (
                                <CircularProgress
                                  sx={{ marginLeft: 1 }}
                                  size={28}
                                />
                              ) : (
                                <>
                                  <IconButton color="info" size="small">
                                    <DoneIcon
                                      onClick={() =>
                                        renameFileAction(file.id, null)
                                      }
                                    />
                                  </IconButton>
                                  <IconButton size="small">
                                    <ClearIcon
                                      onClick={() =>
                                        handleActionClose(file.id, null)
                                      }
                                    />
                                  </IconButton>
                                </>
                              )}
                            </>
                          ) : file.file ? (
                            <Button
                              sx={{
                                display: "block",
                                textAlign: "left",
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textTransform: "none"
                              }}
                              color="inherit"
                              variant="text"
                            >
                              {file?.name}
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                display: "block",
                                textAlign: "left",
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textTransform: "none"
                              }}
                              color="inherit"
                              variant="text"
                              onClick={() => {
                                getdrives(file?.id, file?.name);
                                setCurrentactionobject({
                                  FolderId: file?.id,
                                  DriverId: file?.parentReference?.driveId,
                                });
                              }}
                              style={{
                                pointerEvents: buttondisabled ? "none" : "auto",
                              }}
                            >
                              {file?.name}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {file?.file
                            ? getType(file?.file?.mimeType)
                            : "Folder"}
                        </TableCell>
                        <TableCell>
                          {file?.createdBy?.user?.displayName}
                        </TableCell>
                        <TableCell>
                          {new Date(file.createdDateTime).toDateString()}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            key={`menu-${file?.id}`}
                            aria-label="Close"
                            color="inherit"
                            size="small"
                            onClick={(e) => handleActionClick(file?.id, e)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            id={`${file.id}`}
                            keepMounted
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            anchorEl={anchorEls[file.id]}
                            open={Boolean(anchorEls[file.id])}
                            onClose={(e) => handleActionClose(file.id, e)}
                          >
                            <MenuItem
                              onClick={(e) => handleSetRenameItem(file.id, e)}
                            >
                              Rename
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "87%",
                  width: "100%",
                  bgcolor: "rgba(255, 255, 255, 0.5)",
                  overflow: "hidden",
                }}
              >
                <NoteIcon sx={{ opacity: 0.5, fontSize: 80 }} />
                <Typography variant="h6" sx={{ opacity: 0.5, mt: 2 }}>
                  No Folder
                </Typography>{" "}
              </Box>
            )}
          </React.Fragment>

          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>New Folder</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can create new folder to the current folder level.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="name"
                fullWidth
                variant="standard"
                onChange={(e) => setFolderName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="warning">
                Cancel
              </Button>
              <Button
                disabled={!folderName}
                onClick={() => handleOnCreateFolder()}
                color="primary"
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openFileDialog}
            onClose={() => setOpenFileDialog(false)}
          >
            <DialogTitle>New File</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can upload a file to the current folder level.
              </DialogContentText>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <input
                  type="file"
                  onChange={handleUploadFile}
                  style={{ display: "none" }}
                />
                <Button
                  sx={{
                    backgroundColor: "inherit",
                    color: "black",
                    boxShadow: "none",
                    width: "100%",
                    border: "2px dashed",
                    borderColor: false ? "primary.main" : "grey.300",
                    borderRadius: 1,
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#3c52b2",
                    },
                  }}
                  variant="contained"
                  onClick={() =>
                    document.querySelector('input[type="file"]')["click"]()
                  }
                >
                  Upload a file
                </Button>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenFileDialog(false)} color="info">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Files;
