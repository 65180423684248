import React from "react";

const DocIcon = () => {
  return (
    <div>
      <svg
        className="auHQVc"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.222 0H1.778C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0zm-1.769 5.333H3.556V3.556h8.897v1.777zm0 3.556H3.556V7.11h8.897V8.89zm-2.666 3.555H3.556v-1.777h6.23v1.777z"
          fill="#4285F4"
        ></path>
      </svg>
    </div>
  );
};

export default DocIcon;
