import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Microsoft from "./components/microsoft";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  // const fetchURL = "http://localhost:8000";
  const fetchURL = "https://drive.solarinbound.app";

  return (
    <>
      <CssBaseline />
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Microsoft fetchURL={fetchURL} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
